<template>
  <div>
    <InlineMessageBox header="Important Exchange Information" site="buyer">
      <ul>
        <div v-for="(item, index) in bulletPoint.items" :key="index">
          <li>
            {{ item }}
          </li>
        </div>
      </ul>
    </InlineMessageBox>
    <!-- <bullet-point-box
      :title="bulletPoint.title"
      :items="bulletPoint.items"
      class="bullet-point"
    /> -->
    <FormulateForm>
      <div class="repeatable-group-wrapper">
        <!-- <div class="row">
          <div class="col half">
            <FormulateInput
              v-model="orderOrTicket"
              type="select"
              :options="orderOrTicketOption"
              label="Upgrade Option"
              placeholder="- Please Select -"
              @input="getOrderOrTicketValue"
              ignored
            />
          </div>
        </div> -->

        <div
          :class="isClassName"
          v-for="(repeat, index) in repeatableObject"
          :key="index"
        >
          <div class="row" v-show="isAddTicket">
            <div class="col half">
              <FormulateInput
                v-model="repeatableObject[index].current"
                type="select"
                :options="orderOrTicketList"
                :label="currentDisplayLabel"
                placeholder="- Please Select -"
                @input="getCurrentTicketValue(index)"
                :error="currentErrorMessageDisplay(index)"
                ignored
                autocomplete="false"
              />
            </div>

            <div class="col half">
              <FormulateInput
                v-model="repeatableObject[index].upgrade"
                type="select"
                :options="repeatableObject[index].option"
                :label="upgradeDisplayLabel"
                placeholder="- Tier Name (+$X.XX) -"
                ignored
                autocomplete="false"
              />
            </div>
          </div>

          <div class="row" v-show="isRemoveButton">
            <div class="remove-button-container">
              <div class="remove-button" @click="removeObj(index)">--</div>
            </div>
          </div>
        </div>

        <div class="row" v-show="isAddTicketOrder">
          <div class="add-button" @click="addObj">+ Add Another Ticket</div>
        </div>
      </div>
      <div class="button-exchange">
        <FormulateInput
          type="button"
          name="Exchange"
          @click="submit"
          :disabled="showExhangeButton || isSubmit"
        />
      </div>
    </FormulateForm>
    <div class="divider"></div>
  </div>
</template>

<script>
import BulletPointBox from "@/components/BulletPointBox.vue";
import InlineMessageBox from "@/components/InlineMessageBox.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
import dateFormat from "dateformat";
const TITLE = "Important Exchange Information";
const ITEM01 =
  "Exchanges can only be made on tickets which have the same price.";
const ITEM02 =
  "Your current ticket will no longer be scannable or valid for registration.";
const ITEM03 = "If you would like to upgrade a ticket, click 'upgrade' above.";
const ITEM04 =
  "If you can't select the options below you have not purchased tickets to any events which offer this feature.";
const ORDER = "Order";
const TICKET = "Ticket";
const CURRENT = "Current";
const CURRENT_ORDER = "Current Order";
const CURRENT_TICKET = "Current Ticket";
const UPGRADE = "Upgrade";
const UPGRADE_ORDER = "Upgrade Order";
const UPGRADE_TICKET = "Upgrade Ticket";
export default {
  name: "exchange",
  head() {
    return {
      title: "Exchange",
    };
  },
  components: {
    BulletPointBox,
    AlertModal,
    InlineMessageBox,
  },
  data() {
    return {
      bulletPoint: {
        title: TITLE,
        items: [ITEM01, ITEM02, ITEM03, ITEM04],
      },
      repeatableObject: [
        {
          current: null,
          upgrade: null,
          option: [],
          isUpgradeAvailable: false,
        },
      ],
      orderOrTicket: TICKET,
      formData: {},
      orderOrTicketOption: [ORDER, TICKET],
      orderList: [],
      ticketList: [],
      eventTierList: null,
      isRemoveObj: false,
      currencySign: "$",
      isSubmit: false,
    };
  },
  computed: {
    orderOrTicketList() {
      if (this.orderOrTicket === ORDER) {
        return this.orderList;
      } else if (this.orderOrTicket === TICKET) {
        return this.ticketList;
      } else {
        return [];
      }
    },
    currentDisplayLabel() {
      return this.orderOrTicket === ORDER
        ? CURRENT_ORDER
        : this.orderOrTicket === TICKET
        ? CURRENT_TICKET
        : CURRENT;
    },
    upgradeDisplayLabel() {
      return this.orderOrTicket === ORDER
        ? UPGRADE_ORDER
        : this.orderOrTicket === TICKET
        ? UPGRADE_TICKET
        : UPGRADE;
    },
    isClassName() {
      return this.repeatableObject.length > 1
        ? "repeatable-group-container"
        : "repeatable-group-container2";
    },
    isAddTicket() {
      return this.orderOrTicket !== null ? true : false;
    },
    isAddTicketOrder() {
      return this.orderOrTicket === null
        ? false
        : this.orderOrTicket === ORDER
        ? false
        : true;
    },
    isRemoveButton() {
      return this.repeatableObject.length > 1 ? true : false;
    },
    showExhangeButton() {
      if (this.orderOrTicket === null) {
        return true;
      } else {
        for (const obj of this.repeatableObject) {
          if (obj.current === null || obj.upgrade === null) {
            return true;
          }
        }
        return false;
      }
    },
  },
  methods: {
    async submit() {
      this.isSubmit = true;
      try {
        for (let index = 0; index < this.repeatableObject.length; index++) {
          let ticket_id = this.repeatableObject[index].current[1].orderTicket
            .validation;
          let saleDateId = this.repeatableObject[index].upgrade[0];
          let orderId = this.repeatableObject[index].current[1].orderId;
          let upgradeObj = {
            type: this.orderOrTicket,
            order: {
              _id: orderId,
              ticket_id: ticket_id,
            },
            upgradeOrder: {
              id: saleDateId,
            },
          };
          await this.$axios.post("/toteswap/exchange", upgradeObj);

          if (this.repeatableObject.length - 1 === index) {
            this.clearForm();
            this.$toast(
              "Your exchange has been processed. Redirecting you now.",
              {
                type: "success",
              }
            );
            setTimeout(() => {
              this.$router.push("/my-tickets");
            }, 2000);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    currentErrorMessageDisplay(index) {
      if (this.repeatableObject[index].isUpgradeAvailable) {
        if (this.repeatableObject[index].option.length > 0) {
          this.repeatableObject[index].isUpgradeAvailable = false;
          return "";
        } else {
          return "Sorry, there are no other tickets at your price point.";
        }
      }
    },
    getOrderOrTicketValue() {
      if (this.orderOrTicket !== null) {
        this.repeatableObject = [
          {
            current: null,
            upgrade: null,
            option: [],
            isUpgradeAvailable: false,
          },
        ];
      }
    },
    addObj() {
      this.repeatableObject.push({
        current: null,
        upgrade: null,
        option: [],
        isUpgradeAvailable: false,
      });
    },
    removeObj(index) {
      this.repeatableObject.splice(index, 1);
      this.isRemoveObj = true;
    },
    clearForm() {
      this.isSubmit = false;
      this.orderList = [];
      this.ticketList = [];
      this.repeatableObject = [
        {
          current: null,
          upgrade: null,
          option: [],
          isUpgradeAvailable: false,
        },
      ];
      this.orderOrTicket = TICKET;
    },
    async getCurrentTicketValue(index) {
      if (this.isRemoveObj) {
        this.isRemoveObj = false;
      } else {
        try {
          let orderEventId =
            this.repeatableObject[index].current === undefined ||
            this.repeatableObject[index].current === null
              ? null
              : this.repeatableObject[index].current[1].orderEventId;
          if (orderEventId !== null) {
            let event = this.eventTierList.find((x) => x._id === orderEventId);
            let tempArr = [];
            for (const tier of event.tiers) {
              if (this.orderOrTicket === ORDER) {
                let orders = this.repeatableObject[index].current[1];
                let orderTierList = orders.orderTicketList;
                let orderSaleDateId = orderTierList[0].tierId;
                let orderTierPrice = parseFloat(
                  orderTierList[0].price.$numberDecimal
                );
                if (tier.price === orderTierPrice) {
                  for (const saleDate of tier.saleDates) {
                    if (saleDate.available <= 0) {
                      continue;
                    }
                    if (orderSaleDateId !== saleDate.id) {
                      const startDate = this.formatDate(
                        saleDate.sale_start.$date.$numberLong
                      );
                      const endDate = this.formatDate(
                        saleDate.sale_end.$date.$numberLong
                      );
                      tempArr.push({
                        label:
                          tier.name +
                          `  (${this.currencySign}` +
                          tier.price.toFixed(2) +
                          ")" +
                          " " +
                          startDate +
                          "-" +
                          endDate,
                        value: [saleDate.id, { tierPrice: tier.price }],
                      });
                    }
                  }
                }
              } else {
                let orderTier = this.repeatableObject[index].current[1]
                  .orderTicket;
                if (tier.price === parseFloat(orderTier.price.$numberDecimal)) {
                  for (const saleDate of tier.saleDates) {
                    if (saleDate.available <= 0) {
                      continue;
                    }
                    if (saleDate.id !== orderTier.tierId) {
                      const startDate = this.formatDate(
                        saleDate.sale_start.$date.$numberLong
                      );
                      const endDate = this.formatDate(
                        saleDate.sale_end.$date.$numberLong
                      );
                      tempArr.push({
                        label:
                          tier.name +
                          `  (${this.currencySign}` +
                          tier.price.toFixed(2) +
                          ")" +
                          " " +
                          startDate +
                          "-" +
                          endDate,
                        value: [
                          saleDate.id,
                          { tierPrice: tier.price, tierCount: 1 },
                        ],
                      });
                    }
                  }
                }
              }
            }
            this.repeatableObject[index].upgrade = null;
            this.repeatableObject[index].isUpgradeAvailable = true;
            this.repeatableObject[index].option = tempArr;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    checkOrderListTier(orderTierList) {
      for (let i = 1; i < orderTierList.length; i++) {
        if (orderTierList[0].tier_id !== orderTierList[i].tier_id) {
          return false;
        }
      }
      return true;
    },
    getTierSaleDate(orderEventId, orderTierId, orderSaleDateId) {
      let eventTierList = this.eventTierList.find(
        (event) => event._id === orderEventId
      );
      let eventTier = eventTierList.tiers.find(
        (tier) => tier.id === orderTierId
      );
      return eventTier.saleDates.find(
        (tierSaleDate) => tierSaleDate.id === orderSaleDateId
      );
    },
    formatDate(date) {
      return dateFormat(new Date(parseInt(date)), "mm/dd/yy");
    },
    async fetchOrders() {
      try {
        const { data } = await this.$axios.post("/toteswap/get-orders", {
          plannerPolicy: "enable_exchange_ticket",
          eventUrl: this.$route.params.url || '',
        });
        this.eventTierList = data.events;
        for (const order of data.orders) {
          let tempLabel = order.event_name + " - ";
          let tempValue = [
            order._id,
            {
              orderTicketList: order.tickets,
              orderCount: order.ticket_count,
              orderEventId: order.event_id,
            },
          ];
          let orderTier = order.tickets[0];
          if (!orderTier) {
            continue;
          }
          let eventTierSaleDate = this.getTierSaleDate(
            order.event_id,
            orderTier.tier_id,
            orderTier.saleDateId
          );
          let startDate = this.formatDate(
            eventTierSaleDate.sale_start.$date.$numberLong
          );
          let endDate = this.formatDate(
            eventTierSaleDate.sale_end.$date.$numberLong
          );
          if (this.checkOrderListTier(order.tickets)) {
            let pcs = order.ticket_count === 1 ? "pc" : "pcs";
            tempLabel =
              tempLabel +
              orderTier.tier +
              `  (${this.currencySign}` +
              orderTier.price.$numberDecimal +
              ") " +
              startDate +
              " - " +
              endDate +
              " - " +
              order.ticket_count +
              pcs;
            this.orderList.push({ label: tempLabel, value: tempValue });
          }
          for (let i = 0; i < order.tickets.length; i++) {
            let ticketObj = {
              label:
                order.event_name +
                " - " +
                order.tickets[i].tier +
                `  (${this.currencySign}` +
                order.tickets[i].price.$numberDecimal +
                ") " +
                startDate +
                " " +
                endDate,
              value: [
                order.tickets[i].invoice,
                {
                  orderId: order._id,
                  orderTicket: order.tickets[i],
                  orderEventId: order.event_id,
                },
              ],
            };
            this.ticketList.push(ticketObj);
          }
          tempLabel = tempLabel + " - " + order.ticket_count + "pcs";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.$store.commit("setTitle", "Exchanges");
    this.fetchOrders();
  },
};
</script>

<style lang="less">
.repeatable-group-wrapper {
  .repeatable-group-container {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 0.05fr;
  }
  .repeatable-group-container2 {
    width: 102%;
    display: grid;
  }
  .remove-button-container {
    background: #2bacd9;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: auto;
  }
  .remove-button {
    cursor: pointer;
    -webkit-text-stroke-width: thick;
    font-weight: bolder;
    color: #222429;
    text-align: center;
  }
  .add-button {
    width: 210px;
    margin: 10px auto;
    padding: 8px 16px;
    border: 1px px solid var(--border);
    color: var(--primary-green);
    background: var(--input-background);
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid var(--border);
  }
}
.button-exchange {
  margin-top: 15px;
  text-align: -webkit-right;
  color: aqua;
  .formulate-input-element {
    button {
      background: #00b7f1;
    }
  }
}
</style>
